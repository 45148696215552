import React, { useRef } from 'react'
import ReactIframe from 'react-iframe'
import {motion, useScroll, useTransform} from "framer-motion"
import "./AboutUs.css"

function About({ size }) {
  const textVariants = {
    initial: {
        y: 100,
        opacity: 0,
    },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 1,
            ease: "easeOut",
            staggerChildren: 0.3, // Stagger the children by 0.3s
        },
    },
  };

  const childVariants = {
      initial: { y: 50, opacity: 0 },
      animate: { y: 0, opacity: 1, transition: { duration: 0.6 } },
  };

    const ref = useRef()
    const {scrollYProgress} = useScroll({
        target: ref,
        start: ["start start", "end start"]
    })

  const yBg = useTransform(scrollYProgress, [0, 1], [-500, 500])
  return (
        <section id="about-contact" className='aboutHome container1' ref={ref} >
            <motion.div className='flexSB' style={{yBg}}>
                <div className='left'>
                    <motion.div 
                        initial="initial"
                        whileInView="animate"
                        transition={{
                          duration: 1,
                          ease: "easeOut",
                          delay: 0.2
                        }}
                        exit="exit"
                        viewport={{ once: false, amount: 0.5 }}
                        variants={textVariants}
                        className="aboutHeading">
                        <motion.h1 variants={childVariants}>WHO ARE WE?</motion.h1>
                        {/* <motion.h1>3D Animation</motion.h1> */}
                        <motion.h3 variants={childVariants}>{"Telling Your Story Through Video".toUpperCase()}</motion.h3>
                        <motion.h2 variants={childVariants}>Video Production brings ideas to life through compelling storytelling, and creative cinematography.</motion.h2>
                        <motion.p variants={childVariants}>Video Production isn’t just about shooting scenes-it’s about crafting stories, building trust, and creating experiences. Whether you’re promoting your brand, showcasing a product, or capturing unforgettable moments, videos are your most powerful tool. At Rabbit And Carrot, our video production professionals make sure your brand shines in every frame, delivering top-notch content that resonates. We've been perfecting our craft since 2008, ensuring every video meets the highest standards of creativity and precision.</motion.p>
                    </motion.div>
                    {/* <div className='items'>
                            <h2>Creative Mastery</h2>
                            <h2>Strategic Impact</h2>
                            <h2>Innovative Solutions</h2>
                    </div> */}
                </div>
                <div className="right">
                        <ReactIframe
                            aria-label='Get A Quote' 
                            frameborder="0"
                            style={{ padding: '20px', border: 'none' }}
                            src='https://forms.zohopublic.com/virtualoffice10491/form/GetAFreeConsultation1/formperma/1byXJBg41ryF9ALfP2Mi-il8SnVVL_fy40NvFaDiWQo' 
                            width="100%" 
                            height="610px"
                        />
                </div>
                </motion.div>
        </section>
  )
}

export default About