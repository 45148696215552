import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./Services.css";
import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import '@splidejs/splide/dist/css/splide.min.css';

import client1 from "../../../Assets/Clients/abudhabi-culture-tourism.png"
import client2 from "../../../Assets/Clients/abudhabi-police.png"
import client3 from "../../../Assets/Clients/abudhabi-sewage.png"
import client4 from "../../../Assets/Clients/agitha.png"
import client5 from "../../../Assets/Clients/australia-post.png"
import client6 from "../../../Assets/Clients/become.png"
import client7 from "../../../Assets/Clients/broccoli.png"
import client8 from "../../../Assets/Clients/canon.png"
import client9 from "../../../Assets/Clients/channel.png"
import client10 from "../../../Assets/Clients/dahua.png"
import client11 from "../../../Assets/Clients/emirates-nbd.png"
// import client12 from "../../../Assets/Clients/emirates.png"
import client13 from "../../../Assets/Clients/french-embassy.png"
import client14 from "../../../Assets/Clients/huawei.png"
import client15 from "../../../Assets/Clients/marhaba.png"
import client16 from "../../../Assets/Clients/mok.png"
// import client17 from "../../../Assets/Clients/mok.png"
import client18 from "../../../Assets/Clients/neom.png"
import client19 from "../../../Assets/Clients/pmi.png"
import client20 from "../../../Assets/Clients/reyl.png"
import client21 from "../../../Assets/Clients/rolex.png"
import client22 from "../../../Assets/Clients/rpm.png"
import client23 from "../../../Assets/Clients/saudi-aramco.png"
import client24 from "../../../Assets/Clients/shazam.png"
import client25 from "../../../Assets/Clients/siemens.png"
import client26 from "../../../Assets/Clients/snapchat.png"
import client27 from "../../../Assets/Clients/toshiba.png"
import client28 from "../../../Assets/Clients/yamaha.png"

const clients = [
  { img: client1, },
  { img: client2, },
  { img: client3, },
  { img: client4, },
  { img: client5, },
  { img: client6, },
  { img: client7, },
  { img: client8, },
  { img: client9, },
  { img: client10, },
  { img: client11, },
//   { img: client12, },
  { img: client13, },
  { img: client14, },
  { img: client15, },
  { img: client16, },
  // { img: client17 },
  { img: client18 },
  { img: client19 },
  { img: client21 },
  { img: client22 },
  { img: client23 },
  { img: client24 },
  { img: client25 },
  { img: client26 },
  { img: client27 },
  { img: client28 },
];


const serviceTypes = [
    {
        id: 1,
        title: "Explainer Videos",
        description: "Got a complex idea? Let’s simplify it. We create videos that break down your message in a way that’s easy to understand and engaging. With crisp visuals, clear narration, and smooth transitions, your audience will get it-instantly. As a video production company in Dubai, we specialize in transforming abstract concepts into clear, visual stories that leave a lasting impression."
    },
    {
        id: 2,
        title: "Storytelling Testimonial Videos",
        description: "Customer stories, but make them cinematic. From heartfelt interviews to beautifully shot b-roll, we turn testimonials into mini-movies that connect emotionally and visually with your audience. With our experience as a top creative agency in Dubai, we create videos that not only tell stories but make your audience feel them."
    },
    {
        id: 3,
        title: "Educational Videos",
        description: "Learning shouldn’t be boring. We make it exciting with videos that are vibrant, engaging, and easy to understand. Whether it’s for training, tutorials, or knowledge sharing, your audience will enjoy the journey. As a video production agency, we elevate educational content with visuals that both inform and inspire."
    },
    {
        id: 4,
        title: "Trade Show Videos",
        description: "Trade shows can be noisy, but with the right video, your booth can shine. Think sharp edits, bold visuals, and a message that sticks-designed to draw in crowds and spark conversations. Working with corporate production companies, we create videos that attract attention and make your brand stand out in any trade show environment."
    },
    {
        id: 5,
        title: "Live Action Videos",
        description: "From creative ads to heartfelt stories, live-action video brings an authentic human touch. With cinematic shots, skilled direction, and a bit of magic, we’ll craft something that feels alive and personal. Our videographers in Dubai know how to capture that perfect moment that speaks directly to your audience."
    },
    {
        id: 6,
        title: "Social Video Ads",
        description: "The scroll never stops, but your content can make them pause. Short, snappy, and perfectly designed for every platform, our social video ads grab attention and drive action. With a digital content creation company at your service, you’ll have content that stands out in a crowded feed and pushes your audience to take action."
    },
    {
        id: 7,
        title: "Video Case Studies",
        description: "Numbers and facts are great, but when paired with compelling visuals and storytelling? They become unforgettable. Let’s transform your case studies into polished, shareable videos that show the impact of your work. We’re a video production company with a talent for bringing data to life in a way that captivates and educates."
    },
    // {
    //     id: 8,
    //     title: "Video Courses",
    //     description: "Learning on video feels effortless when it’s done right. Whether it’s quick tutorials or full-fledged courses, we make content that’s visually rich and easy to follow—perfect for engaging learners of all kinds. As a video production company in UAE, we create course content that captivates and ensures your message is clearly communicated."
    // },
    {
        id: 8,
        title: "Brand Videos",
        description: "Your story is unique, and we’re here to help you tell it. From the heart of your business to your boldest vision, our video production services showcase who you are in a way that resonates with your audience. Working with production studios in Dubai and our team of experts, we craft compelling narratives that highlight the essence of your brand."
    },
    {
        id: 9,
        title: "Video Retainer",
        description: "Consistent, high-quality content made simple. With a video retainer, you get regular updates, fresh ideas, and professional visuals that keep your brand ahead of the game. At Rabbit And Carrot, we understand the value of ongoing content creation and provide you with the video production company services that ensure your brand stays relevant and engaging."
    } 
];

export default function Services({size}) {

    const [currentIndex, setCurrentIndex] = useState(0);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setCurrentIndex((prevIndex) => (prevIndex + 1) % service.serviceTypes.length);
    //     }, 2000); // Change every 2 seconds
    //     return () => clearInterval(interval); // Cleanup on unmount
    // }, []);

    const textVariants = {
        initial: {
            y: 200,
            opacity: 0,
        },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 1,
                ease: "easeOut",
                staggerChildren: 0.3, // Stagger the children by 0.3s
            },
        },
      };
    
      const childVariants = {
          initial: { y: 100, opacity: 0 },
          animate: { y: 0, opacity: 1, transition: { duration: 0.6 } },
      };

    const settings = {
        // customPaging: function(i) {
        //   return (
        //     <a>
        //       <img 
        //         src={clientImages[i]} 
        //         alt={`Client ${i + 1}`} 
        //         style={{ width: "20px", height: "20px", objectFit: "cover" }} 
        //     />
        //     </a>
        //   );
        // },
        dots: false,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        beforeChange: (current, next) => setCurrentIndex(next)
      };

      const settings1 = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
            {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: true,
                dots: true
            }
            },
            {
            breakpoint: 600,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                initialSlide: 2
            }
            },
            {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                dots: false
            }
            }
        ]
      };
     
      useEffect(() => {
        const splide = new Splide('.splide', {
            type: 'loop',
            drag: 'free',
            focus: 'center',
            perPage: 6,
            autoScroll: {
                speed: 1,
            },
            pagination: false,
            arrows: false,
            breakpoints: {
                1024: {
                  perPage: 4,
                },
                600: {
                  perPage: 3,
                },
                480: {
                    perPage: 2,
                },
              },
        });

        splide.mount({ AutoScroll });
    }, []);

    return (
        <section id="services" className="services">
            <div className="overlay"></div>
            <div className="services-content">
                <motion.div className="top container1" variants = {textVariants} initial="initial" whileInView="animate">
                    {/* <motion.div className="header-container"> */}
                        <motion.h1 variants={childVariants} className="heading">Video Production provides 
                            <motion.div variants={childVariants} className="service-types">
                            <Slider {...settings}>
                                {serviceTypes.map((ele) => {
                                    return (
                                        <div className="types" key={ele.id}>
                                            <h1>{ele.title}</h1>
                                        </div>
                                    )
                                })}
                            </Slider><motion.p variants={childVariants}>that visually convey engaging narratives.</motion.p> </motion.div> 
                        </motion.h1>
                    {/* </motion.div> */}
                    <motion.p variants={childVariants}>{serviceTypes[currentIndex].description}</motion.p>
                    <motion.a variants={childVariants} href="#about-contact"><button className="btn">
                        Book A Free Consultation
                    </button></motion.a>
                </motion.div>
                <div className="bottom-overlay"></div>
                <div className="splide">
                    <div className="splide__track">
                        <ul className="splide__list">
                        {clients.map((ele, index) => (
                            <li className="splide__slide" key={index}>
                            <img src={ele.img} alt={`Client ${index + 1}`} className="slider-image" />
                            </li>
                        ))}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}